/* eslint-disable no-debugger */
/* eslint-disable no-extra-semi */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useRecordTimesData from '../../../state/recordTimesAdmin/UseRecordTimesData';
import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';

import NavLinks from '../NavLinks';

const useRecordsAdminCleanup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { recordTimesState, setRecordTimesState, getRecordTimes, deleteRecordTimeForAllTables, deleteRecordTime } = useRecordTimesData();
  const [modalState, setModalState] = useState(getInitialModalState());

  const onAddRecordTime = () => {
    navigate(NavLinks.RECORDS_TIMES, { 
      state: { 
        recordListId: location.state.recordListId,
        recordlistInstanceId: location.state.recordlistInstanceId,
        recordGroupId: location.state.recordGroupId,
        recordGroupName: location.state.recordGroupName,
        paramRecordListId: location.state.paramRecordListId,
        recordListName: location.state.recordListName,
        backFromActionPage: false,
        orgUnitId: location.state.orgUnitId,
        filterRecordAgeGroupId: location.state.filterRecordAgeGroupId,
        filterEventId: location.state.filterEventId, 
        filterEventCompetitionGenderTypeId: location.state.filterEventCompetitionGenderTypeId,
        filterCourseId: location.state.filterCourseId
      }});
  }

  const onDeleteTimeRecord = (e, swimTimeRecordId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    deleteRecordTime(swimTimeRecordId);
    setRecordTimesState({
      ...recordTimesState,
      arrayData: [],
      objData: {},
      isArrayLoading: false,
      isArrayLoaded: false,
      isObjLoading: false,
      isObjLoaded: false
    });
  };

  const onDeleteTime = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    deleteRecordTimeForAllTables(modalState.swimTimeRecordId);
    setRecordTimesState({
      ...recordTimesState,
      arrayData: [],
      objData: {},
      isArrayLoading: false,
      isArrayLoaded: false,
      isObjLoading: false,
      isObjLoaded: false
    });
    setModalState({
      ...getInitialModalState()
    });
  };

  const onGoBack = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    navigate(NavLinks.RECORDS_EVENTS, { 
      state: { 
        recordGroupId: location.state.recordGroupId, 
        recordGroupName: location.state.recordGroupName,
        recordListId: location.state.recordListId,
        paramRecordListId: location.state.paramRecordListId,
        recordListName: location.state.recordListName,
        orgUnitId: location.state.orgUnitId,
        backFromActionPage: true,
        filterRecordAgeGroupId: location.state.filterRecordAgeGroupId,
        filterEventId: location.state.filterEventId,
        filterEventCompetitionGenderTypeId: location.state.filterEventCompetitionGenderTypeId,
        filterCourseId: location.state.filterCourseId
      }});
  };

  const onOpenModalClicked = (e, swimTimeRecordId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const recordTimeObj = recordTimesState.arrayData.find(x => x.swimTimeRecordId === swimTimeRecordId);

    setModalState({
      ...modalState,
      swimTimeRecordId: swimTimeRecordId,
      swimmerName: recordTimeObj.name,
      swimTime: formatTimeForDisplay(recordTimeObj.swimTime),
      displayPopUp: true
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState(getInitialModalState());
  };

  useEffect(() => {
    if (recordTimesState.isObjLoaded === true) {
      if (location.state && location.state.recordlistInstanceId > 0) {
        getRecordTimes(location.state.recordlistInstanceId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordTimesState.isObjLoaded]);

  useEffect(() => {
    if (location.state && location.state.recordlistInstanceId > 0) {
      getRecordTimes(location.state.recordlistInstanceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInitialModalState() {
    return {
      displayPopUp: false,
      currentTimeInfo: '',
      modalTitle: 'Delete time from entire database?'
    };
  };

  return {
    recordTimesState,
    modalState,
    onDeleteTimeRecord,
    onDeleteTime,
    onGoBack,
    onModalCanceled,
    onOpenModalClicked,
    onAddRecordTime,
    recordGroupName: location.state.recordGroupName
  }
}

export default useRecordsAdminCleanup;