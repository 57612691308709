/* eslint-disable no-debugger */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useRecordTimesData from '../../../state/recordTimesAdmin/UseRecordTimesData';
import useRecordEligibleTimesData from '../../../state/recordEligibleTimes/UseRecordEligibleTimesData';

import NavLinks from '../NavLinks';

const useRecordsAdminTimes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { recordTimesState, postRecordTimes } = useRecordTimesData();
  const { recordEligibleTimesState, getRecordEligibleTimes } = useRecordEligibleTimesData();
  const [addEligibleRecordTimesState, setEligibleAddRecordTimesState] = useState({ eligibleRecordTimes: [] });

  const onAddRecordTimesCheckbox = (e, swimTimeRecognitionId, swimTimeRelayId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let eligibleTimesArray = addEligibleRecordTimesState.eligibleRecordTimes;
    const idx = eligibleTimesArray.findIndex(x => x.swimTimeRecognitionId === swimTimeRecognitionId);

    if (idx > -1) {
      let removedItem = eligibleTimesArray.splice(idx, 1);
    } else {
      const timeObj = {
        swimTimeRecognitionId: swimTimeRecognitionId,
        swimTimeRelayId: swimTimeRelayId
      }

      eligibleTimesArray.push(timeObj);
    }

    setEligibleAddRecordTimesState({
      ...addEligibleRecordTimesState,
      eligibleRecordTimes: eligibleTimesArray
    });
  }

  const onBack = () => {
    navigate(NavLinks.RECORDS_CLEANUP,
      {
        state: {
          recordGroupName: location.state.recordGroupName,
          recordListId: location.state.recordListId,
          recordGroupId: location.state.recordGroupId,
          recordlistInstanceId: location.state.recordlistInstanceId,
          paramRecordListId: location.state.paramRecordListId,
          recordListName: location.state.recordListName,
          orgUnitId: location.state.orgUnitId,
          backFromActionPage: false,
          filterRecordAgeGroupId: location.state.filterRecordAgeGroupId,
          filterEventId: location.state.filterEventId,
          filterEventCompetitionGenderTypeId: location.state.filterEventCompetitionGenderTypeId,
          filterCourseId: location.state.filterCourseId
        }
      });
  }

  const onAddSelectedRecords = () => {
    let recordTimesObj = {
      recordlistInstanceId: location.state.recordlistInstanceId,
      records: []
    };

    const recordArray = addEligibleRecordTimesState.eligibleRecordTimes;
    let recordObj = {};

    recordArray.forEach(record => {
      recordObj = {
        swimTimeRecognitionId: record.swimTimeRecognitionId,
        swimTimeRelayId: record.swimTimeRelayId || 0
      }
      recordTimesObj.records.push(recordObj);
    });

    postRecordTimes(recordTimesObj);

    navigate(NavLinks.RECORDS_CLEANUP,
      {
        state: {
          recordGroupName: location.state.recordGroupName,
          recordListId: location.state.recordListId,
          recordGroupId: location.state.recordGroupId,
          recordlistInstanceId: location.state.recordlistInstanceId,
          paramRecordListId: location.state.paramRecordListId,
          recordListName: location.state.recordListName,
          orgUnitId: location.state.orgUnitId,
          backFromActionPage: false,
          filterRecordAgeGroupId: location.state.filterRecordAgeGroupId,
          filterEventId: location.state.filterEventId,
          filterEventCompetitionGenderTypeId: location.state.filterEventCompetitionGenderTypeId,
          filterCourseId: location.state.filterCourseId
        }
      });
  }

  useEffect(() => {
    if (location.state && location.state.recordlistInstanceId > 0) {
      getRecordEligibleTimes(location.state.recordlistInstanceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    recordTimesState,
    recordEligibleTimesState,
    addEligibleRecordTimesState,
    onAddRecordTimesCheckbox,
    onAddSelectedRecords,
    onBack
  }
}

export default useRecordsAdminTimes;