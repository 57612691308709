import { Fragment } from "react";
import Headings from "../../../../common/components/headings/Headings";
import EventCombobox from "../../../../common/components/comboboxes/eventCombobox/EventCombobox";

import useRecordsAdminListInstanceDetail from "./UseRecordsAdminListInstanceDetail";
import Dropdown from "../../../../common/components/dropdowns/Dropdown";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Constants from "../../../../common/utils/Constants";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import global from '../../../../common/components/GlobalStyle.module.css';

const RecordsAdminListInstanceDetail = () => {
  const { 
    formState, 
    errorState,
    recordAgeGroupState, 
    handleSubmit, 
    onFormValueChanged, 
    onValueTextPairChanged, 
    onCancelClicked,
    competitionGenderTypeOptions} = useRecordsAdminListInstanceDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Records Admin List Instance</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <EventCombobox
              label='Event'
              name='eventId'
              valueToMatch={formState.eventName}
              error={errorState.eventId}
              message={errorState.eventId}
              onChange={(newValue, newValueLabel) => onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName')} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Event Competition Gender"
              name="eventCompetitionGenderTypeId"
              options={competitionGenderTypeOptions}
              value={formState.eventCompetitionGenderTypeId}
              error={errorState.eventCompetitionGenderTypeId}
              message={errorState.eventCompetitionGenderTypeId}
              isLoading={false}
              onChange={(value) => { onFormValueChanged('eventCompetitionGenderTypeId', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {recordAgeGroupState.dataAsOptionsWithNames !== undefined &&
            <Dropdown
              label="Age Groups"
              name="recordAgeGroupId"
              options={recordAgeGroupState.dataAsOptionsWithNames}
              value={formState.recordAgeGroupId}
              error={errorState.recordAgeGroupId}
              message={errorState.recordAgeGroupId}
              isLoading={recordAgeGroupState.isArrayLoading}
              onChange={(value) => { onFormValueChanged('recordAgeGroupId', value) }} 
            />
            }
          </div>
        </div>
        {errorState.duplicateRecords &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.duplicateRecords}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={recordAgeGroupState.isArrayLoading} />
    </Fragment>);
}

export default RecordsAdminListInstanceDetail;