/* eslint-disable no-debugger */
import { Fragment } from "react";
import useRecordsAdminEvents from "./UseRecordsAdminEvents";
import Headings from "../../../../common/components/headings/Headings";
import RecordsAdminEventsGrid from "./RecordsAdminEventsGrid";
import Dropdown from "../../../../common/components/dropdowns/Dropdown";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Constants from "../../../../common/utils/Constants";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import CourseDropdown from "../../../../common/components/dropdowns/courseDropdown/CourseDropdown";

const Form = ({ handleSubmit, formState, errorState, eventState, filteredEventsState, recordAgeGroupState, onFormValueChanged, onValueTextPairChanged, competitionGenderTypeOptions }) => (
  <form onSubmit={handleSubmit} noValidate>
    {eventState.arrayData?.length > 0 &&
      <div className="row">
        <div className="col-xs-12 col-sm-3 col-md-3">
          <CourseDropdown
            label="Course"
            name="courseId"
            value={formState.courseId}
            error={errorState.courseId}
            message={errorState.courseId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'courseId', newValueLabel, 'courseName', e); }} />
        </div>
        <div className="col-xs-12 col-sm-3 col-md-3">
          <Dropdown
            label="Events"
            options={filteredEventsState}
            name="eventId"
            value={formState.eventId}
            error={errorState.eventId}
            message={errorState.eventId}
            isLoading={false}
            onChange={(value) => { onFormValueChanged('eventId', value) }}
          />
        </div>
        <div className="col-xs-12 col-sm-3 col-md-3">
          <Dropdown
            label="Event Gender"
            name="eventCompetitionGenderTypeId"
            options={competitionGenderTypeOptions}
            value={formState.eventCompetitionGenderTypeId}
            error={errorState.eventCompetitionGenderTypeId}
            message={errorState.eventCompetitionGenderTypeId}
            isLoading={false}
            onChange={(value) => { onFormValueChanged('eventCompetitionGenderTypeId', value) }} />
        </div>
        <div className="col-xs-12 col-sm-3 col-md-3">
          {recordAgeGroupState.dataAsOptionsWithNames !== undefined &&
            <Dropdown
              label="Age Groups"
              name="recordAgeGroupId"
              options={recordAgeGroupState.dataAsOptionsWithNames}
              value={formState.recordAgeGroupId}
              error={errorState.recordAgeGroupId}
              message={errorState.recordAgeGroupId}
              isLoading={recordAgeGroupState.isArrayLoading}
              onChange={(value) => { onFormValueChanged('recordAgeGroupId', value) }}
            />
          }
        </div>
        <div className="col-xs-12 col-sm-3 col-md-3" style={{ paddingTop: 25 }}>
          <PrimaryButton type="submit">Filter Records</PrimaryButton>&nbsp;
        </div>
      </div>
    }
  </form>
)

const RecordsAdminEvents = () => {
  const {
    recordsState,
    eventState,
    filteredEventsState,
    recordAgeGroupState,
    formState,
    errorState,
    handleSubmit,
    onCleanupRecords,
    onFormValueChanged,
    onValueTextPairChanged,
    recordEvents,
    competitionGenderTypeOptions,
    onAddEvent,
    onBack,
    onDeleteRecordListInstance,
    getGenderDescription
  } = useRecordsAdminEvents();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Records Admin Events</Headings.H3>
        </div>
      </div>
      <Form
        handleSubmit={handleSubmit}
        formState={formState}
        errorState={errorState}
        eventState={eventState}
        filteredEventsState={filteredEventsState}
        recordAgeGroupState={recordAgeGroupState}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged}
        competitionGenderTypeOptions={competitionGenderTypeOptions}
      />
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          {recordsState.isArrayLoaded === true &&
          <>
          <ActionIntraPageButton type="button" onClick={onAddEvent}>Add Event</ActionIntraPageButton>&nbsp;
          <SecondaryButton type="button" onClick={onBack}>Back</SecondaryButton>
          </>
          }
        </div>
        <div className="col-xs-12">
          <RecordsAdminEventsGrid
            onCleanupRecords={onCleanupRecords}
            data={recordEvents}
            getGenderDescription={getGenderDescription}
            onDeleteRecordListInstance={onDeleteRecordListInstance}
          />
        </div>
      </div>

      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={eventState.isArrayLoading} />
    </Fragment>);
}

export default RecordsAdminEvents;