/* eslint-disable no-debugger */
/* eslint-disable no-extra-semi */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useRecordsData from '../../../state/recordsAdmin/UseRecordsData';
import validate from './RecordsAdminSearchValidation';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import useRecordGroupData from '../../../../common/state/time/recordGroup/UseRecordGroupData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import NavLinks from '../NavLinks';

const useRecordsAdminSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { recordsState, searchRecords } = useRecordsData();
  const { recordGroupState, getRecordGroupByOrgUnitId } = useRecordGroupData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { errorState, formState, handleSubmit, onFormValueChanged, onValueTextPairChanged, setFormState, setIsDirty } = useForm(getInitialFormState, submitFormCallback, validate);

  const [deleteState, setDeleteState] = useState({ isDeleted: false });
  
  const onView = (recordListId) => {
    const recordGroupId = formState.recordGroupId === -1 ? recordGroupState.arrayData[1].recordGroupId : formState.recordGroupId;
    const paramRecordListId = formState.recordListId === -1 ? 0 : formState.recordListId;

    navigate(NavLinks.RECORDS_EVENTS, { 
      state: { 
        recordListId: recordListId, 
        paramRecordListId: paramRecordListId,
        recordListName: formState.recordListName,
        recordGroupId: recordGroupId, 
        recordGroupName: formState.groupName, 
        orgUnitId: selectOrgUnitState.orgUnitId === USAS_ORG_UNIT_ID ? 0 : selectOrgUnitState.orgUnitId
      } 
    });
  }
  
  useEffect(() => {
    if (recordGroupState.isArrayLoaded === false && recordGroupState.isArrayLoading === false) {
      getRecordGroupByOrgUnitId(selectOrgUnitState.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //AutoRun if only one RoleGroup
  useEffect(() => {
    if (
      recordGroupState.isArrayLoaded === true && 
      recordGroupState.arrayData.length === 2 &&
      recordsState.isArrayLoading === false && recordsState.isArrayLoaded === false) {
        searchRecords({ recordGroupId: recordGroupState.arrayData[1].recordGroupId, recordListId: 0, orgUnitId: selectOrgUnitState.orgUnitId});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordGroupState, recordsState]);

  useEffect(() => {
    if (deleteState.isDeleted === true && recordsState.isSaving === false) {
      setDeleteState({...deleteState, isDeleted: false});
        
      searchRecords({ 
        recordGroupId: formState.recordGroupId, 
        recordListId: formState.recordListId === -1 ? 0 : formState.recordListId, 
        orgUnitId: 0 
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsState, deleteState]);

  useEffect(() => {
    if (location.state && location.state.recordGroupId > 0) {
      searchRecords({ recordGroupId: location.state.recordGroupId, recordListId: location.state.paramRecordListId, orgUnitId: location.state.orgUnitId});
      setFormState({
        ...formState,
        recordGroupId: location.state.recordGroupId,
        recordListId: location.state.paramRecordListId,
        recordListName: location.state.recordListName,
        groupName: location.state.recordGroupName
      });

      setIsDirty(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const createSearchFilterObject = () => {
    const filterObject = {
      recordGroupId: formState.recordGroupId || 0,
      recordListId: formState.recordListId === Constants.DEFAULT_ID ? 0 : formState.recordListId,
      orgUnitId: formState.orgUnitId || 0
    }

    return filterObject;
  }

  function submitFormCallback() {
    searchRecords(createSearchFilterObject());
  }

  function getInitialFormState() {
    return {
      recordGroupId: Constants.DEFAULT_ID,
      groupName: '',
      recordListId: 0,
      recordListName: '',
      orgUnitId: 0
    };
  };

  return {
    recordsState,
    formState,
    handleSubmit,
    errorState,
    recordGroupState,
    selectOrgUnitState,
    onFormValueChanged,
    onValueTextPairChanged,
    onView
  }
};

export default useRecordsAdminSearch;