/* eslint-disable no-debugger */
/* eslint-disable no-extra-semi */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useForm from '../../../../common/utils/UseForm';
import useEventData from '../../../../common/state/event/UseEventData';
import useRecordsData from "../../../state/recordsAdmin/UseRecordsData";
import useRecordAgeGroupData from '../../../state/recordAgeGroup/UseRecordAgeGroupData';
import Constants from '../../../../common/utils/Constants';

import validate from './RecordsAdminEventsValidation';

import NavLinks from '../NavLinks';

const competitionGenderTypeOptions = [
  { id: Constants.DEFAULT_ID, name: "All Genders" },
  { id: 1, name: "Male" },
  { id: 2, name: "Female" }];

const useRecordsAdminEvents = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { recordsState, searchRecords, clearObjData, deleteRecordListInstanceReturnArray } = useRecordsData();
  const { eventState, getEvents } = useEventData();
  const { recordAgeGroupState, getRecordAgeGroups } = useRecordAgeGroupData();
  const [filteredRecordsState, setFilteredRecordsState] = useState([]);
  const [filteredEventsState, setFilteredEventsState] = useState([]);
  const { errorState, formState, handleSubmit, onFormValueChanged, onValueTextPairChanged, setFormState } = useForm(getInitialFormState, submitFormCallback, validate);

  function filterEventList() {
    if (eventState.arrayData.length > 0) {
      const eventListArray = eventState.arrayData;
      let filteredEventListArray = [];
      let itemsForDropdown = [];
      
      filteredEventListArray = formState.courseId === Constants.DEFAULT_ID ? eventListArray : eventListArray.filter(x => x.courseId === formState.courseId);

      if (filteredEventListArray[0].courseCode !== '--') {
        filteredEventListArray.unshift({ courseId: Constants.DEFAULT_ID, courseCode: '--' });
      }
    
      itemsForDropdown = filteredEventListArray.map(x => { return { id: x.eventId, name: x.eventName }; })

      setFilteredEventsState(itemsForDropdown);
    }
  }

  function filterEventGrid() {
    if (recordsState.arrayData.length > 0) {
      const eventsArray = recordsState.arrayData.find(x => x.recordListId === location.state.recordListId).recordEvents;
      let filteredArray = eventsArray;

      if (formState.courseId != Constants.DEFAULT_ID && formState.courseId !== undefined) {
        filteredArray =
          filteredArray.filter(x => x.courseId === formState.courseId
          );
      }

      const filterEventId = (formState.eventId === -1 || formState.eventId === undefined) ? location.state.filterEventId : formState.eventId;
      if (filterEventId !== Constants.DEFAULT_ID && filterEventId !== undefined) {
        filteredArray =
          filteredArray.filter(x => x.eventId === filterEventId);
      }

      if (formState.eventCompetitionGenderTypeId !== Constants.DEFAULT_ID && formState.eventCompetitionGenderTypeId !== undefined) {
        filteredArray =
          filteredArray.filter(x => x.eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId
          );
      }

      if (formState.recordAgeGroupId !== Constants.DEFAULT_ID && formState.recordAgeGroupId !== undefined) {
        filteredArray =
          filteredArray.filter(x => x.recordAgeGroupId === formState.recordAgeGroupId
          );
      }

      setFilteredRecordsState(filteredArray);
    }
  }

  function submitFormCallback() {
    filterEventGrid();
  }

  const onBack = () => {
    clearObjData();
    navigate(NavLinks.RECORDS_ADMIN, {
      state: {
        recordGroupId: location.state.recordGroupId,
        recordListId: location.state.recordListId,
        paramRecordListId: location.state.paramRecordListId,
        recordListName: location.state.recordListName,
        orgUnitId: location.state.orgUnitId
      }
    });
  }

  const onAddEvent = () => {
    clearObjData();
    navigate(NavLinks.RECORDS_LIST_INSTANCE_DETAIL, {
      state: {
        recordGroupId: location.state.recordGroupId,
        recordListId: location.state.recordListId,
        paramRecordListId: location.state.paramRecordListId,
        recordListName: location.state.recordListName,
        orgUnitId: location.state.orgUnitId,
        backFromActionPage: false,
        filterRecordAgeGroupId: formState.recordAgeGroupId,
        filterEventId: formState.eventId,
        filterEventCompetitionGenderTypeId: formState.eventCompetitionGenderTypeId,
        filterCourseId: formState.courseId 
      }
    });
  }

  const onCleanupRecords = (recordlistInstanceId) => {
    navigate(NavLinks.RECORDS_CLEANUP, {
      state: {
        recordlistInstanceId,
        recordGroupId: location.state.recordGroupId,
        recordGroupName: location.state.recordGroupName,
        recordListId: location.state.recordListId,
        paramRecordListId: location.state.paramRecordListId,
        recordListName: location.state.recordListName,
        orgUnitId: location.state.orgUnitId,
        backFromActionPage: false,
        filterRecordAgeGroupId: formState.recordAgeGroupId,
        filterEventId: formState.eventId,
        filterEventCompetitionGenderTypeId: formState.eventCompetitionGenderTypeId,
        filterCourseId: formState.courseId
      }
    });
  }

  const onDeleteRecordListInstance = (recordListInstanceId) => {
    deleteRecordListInstanceReturnArray(recordListInstanceId);
  }

  useEffect(() => {
    
    if (location.state.backFromActionPage === true) {
      setFormState({
        ...formState,
        recordAgeGroupId: location.state.filterRecordAgeGroupId,
        eventId: location.state.filterEventId,
        eventCompetitionGenderTypeId: location.state.filterEventCompetitionGenderTypeId,
        courseId: location.state.filterCourseId
      })

      searchRecords({
        recordGroupId: location.state.recordGroupId,
        recordListId: location.state.paramRecordListId === -1 ? 0 : location.state.paramRecordListId,
        //orgUnitId: 0
        orgUnitId: location.state.orgUnitId
      });
    }

    if (location.state && location.state.recordListId > 0 && recordsState.isArrayLoaded === true) {
      if (eventState.isArrayLoaded === false && eventState.isArrayLoading === false) {
        getEvents();
      }

      if (recordAgeGroupState.isArrayLoaded === false && recordAgeGroupState.isArrayLoading === false) {
        getRecordAgeGroups();
      }

      if (recordsState.isArrayLoaded === true && recordsState.isSaving === false) {
        filterEventGrid();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (recordsState.isArrayLoaded === true && recordsState.isSaving === false) {
      filterEventGrid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsState]);

  useEffect(() => {
    if (eventState.isArrayLoaded === true) {
      filterEventList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventState, formState.courseId]);


  useEffect(() => {
    if (filteredEventsState.length > 0 && location.state.filterEventId !== Constants.DEFAULT_ID) {
      setFormState({ ...formState, eventId: location.state.filterEventId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredEventsState]);

  

  function getInitialFormState() {
    return {
      courseId: Constants.DEFAULT_ID,
      eventId: Constants.DEFAULT_ID,
      eventCompetitionGenderTypeId: Constants.DEFAULT_ID,
      recordAgeGroupId: Constants.DEFAULT_ID
    };
  };

  const getGenderDescription = (genderTypeId) => {
    let genderDesc = "";
    if (genderTypeId === 1) {
      genderDesc = "Male";
    } else if (genderTypeId === 2) {
      genderDesc = "Female";
    } else {
      genderDesc = "Mixed"
    }

    return genderDesc;
  }

  return {
    recordsState,
    eventState, 
    filteredEventsState, 
    recordAgeGroupState,
    formState,
    errorState,
    handleSubmit,
    onCleanupRecords,
    onFormValueChanged,
    onValueTextPairChanged,
    recordEvents: filteredRecordsState,
    competitionGenderTypeOptions: competitionGenderTypeOptions,
    onAddEvent,
    onBack,
    onDeleteRecordListInstance,
    getGenderDescription  }
}

export default useRecordsAdminEvents;